.calendar-todo-list {
  text-align: left;
  padding: 0;
  list-style: none;
}

.calendar-todo-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-todo-item-badge {
  vertical-align: top;
  margin-top: 8px;
  width: 6px;
  height: 6px;
}

.active {
  background: rgb(208 248 255);
  border: solid 1px #fff;
}

.dayorder-calendar-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.day-order-calendar-date-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.day-order-calendar-date-buttons button {
  font-size: 10px;
  padding: 0 5px;
  font-weight: 700;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.rs-calendar-table-cell-content:hover {
  background-color: inherit !important;
}

.day-order-calendar-date-buttons button:hover {
  color: #000 !important;
}

.day-order-calendar-date-buttons button.button1 {
  background-color: #55cbcd;
}

.day-order-calendar-date-buttons button.button2 {
  background-color: #cbaacb;
}

.day-order-calendar-date-buttons button.button3 {
  background-color: #c6dbda;
}

.day-order-calendar-date-buttons button.button4 {
  background-color: #fcb9aa;
}
